export const organizationSchema = {
  "script:ld+json": {
    "@context": "https://schema.org",
    "@type": "Organization",
    url: "https://www.updrinks.de/",
    logo: "https://www.updrinks.de/logo.svg",
    name: "UpDrinks GmbH",
    address: {
      "@type": "PostalAddress",
      postalCode: "64625",
      streetAddress: "Lilienthalstraße 51",
      addressCountry: "DE",
    },
    legalName: "UPdrinks GmbH",
    vatID: "DE365595940",
    alternateName: "Voll",
  },
};

export const noIndex = {
  name: "robots",
  content: `noindex, nofollow`,
};
